import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Avatar from '@shared/ui/display/Avatar';
import { ReactComponent as BinanceCircleIcon } from '@icons/exchanges/binance-circle.svg';
import { ReactComponent as TraderVerifiedIcon } from '@icons/custom/trader-verified.svg';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { Body, BodyLargeSemiBold } from '@components/styled/Typography';
import Icon from '@shared/ui/icons/Icon';
import withTextEllipsis from '@shared/ui/tooltips/withTextEllipsis';
import PortfolioSubscribers from '../PortfolioSubscribers';
import PortfolioSubscriptionPrice from '../PortfolioSubscriptionPrice';
const Container = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.m,
}));
const PortfolioNameContainer = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: 4,
}));
const PortfolioName = styled(withTextEllipsis(BodyLargeSemiBold))(() => ({
    letterSpacing: '-0.108px',
    lineHeight: '22px', // min height that allows to fit simbols like 'g' with 'overflow: hidden'
}));
// TODO: remove explicit with/height
const AvatarContainer = styled(ContainerColumn)(() => ({
    position: 'relative',
    width: '63px',
    height: '54px',
    flexShrink: 0,
    alignItems: 'end',
}));
const ExchangeIconBadge = styled.div(() => ({
    display: 'inline-flex',
    position: 'absolute',
    top: -3,
    left: 0,
    borderRadius: '70px',
    border: '3px solid var(--White, #FFF)',
}));
const HeadlineContainer = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: 'auto',
    gap: 4,
}));
const HeadlineDetails = styled(ContainerRow)((props) => ({
    height: 'auto',
    color: props.theme.palette.text.secondary,
    gap: props.theme.spacing_sizes.m,
}));
const PortfolioHeadline = ({ className = undefined, portfolio }) => {
    const { t } = useTranslation();
    const tradeType = useMemo(() => {
        let type = '';
        if (portfolio.type === 'spot') {
            type = t('portfolio.trade_types.spot');
        }
        else if (portfolio.type === 'margin') {
            if (!portfolio.referral_asset) {
                type = t('portfolio.trade_types.margin');
            }
            else if (portfolio.referral_asset === 'coin') {
                type = t('portfolio.trade_types.coinm');
            }
            else if (portfolio.referral_asset === 'usdt') {
                type = t('portfolio.trade_types.usdtm');
            }
        }
        return type.toUpperCase();
    }, [portfolio.type, portfolio.referral_asset, t]);
    return (_jsxs(Container, { className: className, children: [_jsxs(AvatarContainer, { children: [_jsx(ExchangeIconBadge, { children: _jsx(Icon, { size: 24, IconComponent: BinanceCircleIcon, keepOriginalColor: true }) }), _jsx(Avatar, { variant: 'img', size: 54, imgUrl: portfolio.logo })] }), _jsxs(HeadlineContainer, { children: [_jsxs(PortfolioNameContainer, { children: [_jsx(PortfolioName, { tooltipText: portfolio.portfolioName, children: portfolio.portfolioName }), _jsx(Icon, { size: 16, color: '#D6DCE0', IconComponent: TraderVerifiedIcon })] }), _jsxs(HeadlineDetails, { children: [_jsx(PortfolioSubscribers, { followersCount: portfolio.followers.count, userQueuePosition: portfolio.user_subscription_summary ?
                                    portfolio.user_subscription_summary.queue_position :
                                    undefined }), _jsx(PortfolioSubscriptionPrice, { price: portfolio.subscriptionPrice }), _jsx(Body, { children: tradeType })] })] })] }));
};
export default PortfolioHeadline;
